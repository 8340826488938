<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex pa-4 xs12 text-center>
        <v-card>
          <v-layout wrap justify-center>
            <v-flex
              pl-4
              pt-2
              pb-2
              xs12
              text-left
              style="background-color: black"
            >
              <span style="font-size: 20px; color: white"
                >Nature Camp Details</span
              >
            </v-flex>
            <v-flex xs12>
              <v-layout wrap>
                <v-flex pl-3 xs4 text-left align-self-center>
                  <v-autocomplete
                    outlined
                    dense
                    color="black"
                    :items="districts"
                    v-model="selectedDistrict"
                    item-text="name"
                    item-value="name"
                    item-color="#FF1313"
                    hide-details
                    clearable
                    class="custom-autocomplete mainfont"
                  >
                    <template v-slot:label>
                      <span
                        class="custom-label-text"
                        style="color: black; font-size: 14px"
                        >District *</span
                      >
                    </template>
                    <template v-slot:append>
                      <v-icon color="black">mdi-chevron-down</v-icon>
                    </template>
                  </v-autocomplete>
                </v-flex>

                <v-flex xs4 pl-3 align-self-center pt-6>
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    max-width="290"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        clearable
                        dense
                        outlined
                        readonly
                        v-model="startDate"
                        v-bind="attrs"
                        background-color="white"
                        v-on="on"
                        @click:clear="fromDate = null"
                        style="color: black"
                      >
                        <template v-slot:append>
                          <v-icon color="black">mdi-chevron-down</v-icon>
                        </template>
                        <template v-slot:label>
                          <span
                            class="custom-label-text"
                            style="color: black; font-size: 14px"
                            >From Date</span
                          >
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      color="#13736f"
                      @change="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs4>
                  <v-layout wrap justify-center>
                    <v-flex xs6 align-self-center>
                      <span>Duration of Camp *</span>
                    </v-flex>
                    <v-flex xs4>
                      <v-radio-group v-model="campDuration">
                        <v-radio label="1 day" value="1"></v-radio>
                        <v-radio label="2 days" value="2"></v-radio>
                        <v-radio label="3 days" value="3"></v-radio>
                      </v-radio-group>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs6>
              Selected camp at Pamba on {{ startDate }} can accommodate people
              as follows
            </v-flex>
            <v-flex xs7 pt-3 pb-9>
              <v-card
                elevation="0"
                style="border: 1px solid black; border-radius: 0px"
              >
                <v-layout wrap justify-center>
                  <v-flex xs12>
                    <v-layout wrap justify-center>
                      <v-flex xs3 pt-2 text-center>
                        <span>Staff/Group Leader</span>
                      </v-flex>
                      <v-flex pt-2 xs3 text-center>
                        <span>Male Members</span>
                      </v-flex>
                      <v-flex pt-2 xs3 text-center>
                        <span>Female Members</span>
                      </v-flex>
                      <v-flex pt-2 xs3 text-center>
                        <span>Transgender Members</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap justify-center>
                      <v-flex pb-2 xs3 text-center>
                        <span>{{ list.totalMembers }}</span>
                      </v-flex>
                      <v-flex pb-2 xs3 text-center v-if="list">
                        <span v-if="list.membersLimit">{{
                          list.membersLimit.male
                        }}</span>
                      </v-flex>
                      <v-flex pb-2 xs3 text-center v-if="list">
                        <span v-if="list.membersLimit">{{
                          list.membersLimit.female
                        }}</span>
                      </v-flex>
                      <v-flex pb-2 xs3 text-center v-if="list">
                        <span v-if="list.membersLimit">{{
                          list.membersLimit.transgender
                        }}</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
  
<script>
import axios from "axios";

export default {
  props: {
    campid: String,
    textEvent: String,
  },
  data() {
    const today = new Date().toISOString().substr(0, 10);
    return {
      startDate: today,
      list: {},
      selectedDistrict: null,
      districts: [
        "Thiruvananthapuram",
        "Kollam",
        "Pathanamthitta",
        "Alappuzha",
        "Kottayam",
        "Idukki",
        "Ernakulam",
        "Thrissur",
        "Palakkad",
        "Malappuram",
        "Kozhikode",
        "Wayanad",
        "Kannur",
        "Kasaragod",
      ],
      campDuration: "",
      menu1: false,
      male: null,
      // startDate: null
    };
  },

  mounted() {
    this.checkAvailability();
  },

  watch: {
    startDate() {
      this.checkAvailability();
    },
    campDuration: "emitCampDetailsUpdated",
    selectedDistrict: "emitCampDetailsUpdated",
  },
  methods: {
    checkAvailability() {
      let selectedDuration;
      if (this.campDuration === "1") {
        selectedDuration = 1;
      } else if (this.campDuration === "2") {
        selectedDuration = 2;
      } else if (this.campDuration === "3") {
        selectedDuration = 3;
      } else {
        selectedDuration = null;
      }

      axios({
        method: "POST",
        url: "/check/availability",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          date: this.startDate,
          duration: selectedDuration,
          campid: this.$route.query.id,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.list = response.data.natureCampDetails;
            this.emitCampDetailsUpdated();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },
    emitCampDetailsUpdated() {
      this.$emit("camp-details-updated", {
        startDate: this.startDate,
        duration: this.campDuration,
        selectedDistrict: this.selectedDistrict,
      });
    },
  },
};
</script>
